import Grid from '@components/common/Grid';
import React from 'react';
import SEO from '@components/common/SEO';
import useGeneralContext from '@components/hoc/useGeneralContext';

export default function NonTrovata() {
  const { setActiveLang } = useGeneralContext();
  const seo = {
    title: 'Copetti Antiquari - Pagina non trovata',
    noIndex: true,
  };
  const language = 'it';

  React.useEffect(() => {
    setActiveLang('IT');
  }, []);

  return (
    <>
      <SEO seo={seo} language={language} />
      <Grid>
        <div className="col-span-12">
          <h1>404</h1>
          <p>Pagina non trovata!</p>
        </div>
      </Grid>
    </>
  );
}
